import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { auth, googleProvider } from "../firebaseConfig";
import { signInWithPopup, signOut } from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import AppleSigninButton from "react-apple-signin-auth";
import { useNavigate } from "react-router-dom";

import appleLogo from "../assets/images/appleLogo.svg";
import googleLogo from "../assets/images/googleLogo.svg";
import profileImg from "../assets/images/profile.svg";
import proIcon from "../assets/images/proIcon.svg";
import lock from "../assets/images/lock.svg";
import unlimWords from "../assets/images/unlimWords.svg";

import cross from "../assets/images/crossLogin.svg";
import googleLogoWhite from "../assets/images/googleLogoWhite.svg";
import arrowRight from "../assets/images/arrowRight.svg";

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  top: 10px;
  height: 60px; /* Фиксированная высота */
  background-color: #000;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const Title = styled.h2`
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding: 0 auto;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const SignInContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Контент ближе к верхнему краю */
  align-items: center;
  min-height: 100vh;
  background-color: #000;
  color: #fff;
  text-align: center;
  width: 100%;
  padding-top: 60px; /* Учитываем HeaderContainer */
  position: relative;
`;

const SignInBox = styled.div`
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 40px; /* Минимальный отступ */
  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

const InfoText = styled.div`
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  color: rgba(255, 255, 255, 0.5);
`;

const Text = styled.div`
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding-bottom: 10px;
`;

const ProInfoText = styled.div`
  //styleName: 16 regular;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: rgba(255, 255, 255, 0.5);
  padding-top: 10px;
`;

const SignInButton = styled.button`
  background: rgba(34, 37, 46, 1);
  border: none;
  color: #fff;
  width: 342px;
  height: 48px;
  padding: 12px;
  margin: 10px 0;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.17);
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 80px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  @media (max-width: 768px) {
    right: 20px;
    top: 14px;
  }
`;

const AccountBox = styled.div`
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 40px; /* Минимальный отступ */
  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

const Avatar = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 50%;
`;

const ImageIcon = styled.img`
  // width: 64px;
  // height: 64px;
  // border-radius: 50%;
`;

const ArrowImg = styled.img`
  margin-left: auto;
`;

const ImgClose = styled.img`
  width: 48px;
  height: 48px;
  // border-radius: 50%;
  // margin-bottom: 10px;
  @media (max-width: 768px) {
    width: 32px;
    height: 32px;
  }
`;

const LogoutButton = styled(SignInButton)`
  background-color: #1c1f26;
  margin-top: 20px;
  //styleName: 16 regular;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: rgba(255, 255, 255, 0.7);
`;

const ProBox = styled.div`
  background-color: #1c1f26;
  padding: 12px;
  border-radius: 12px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  // justify-content: space-between;
`;

const ProText = styled.div`
  text-align: left;
  margin-left: 12px;
  color: white;
`;

const ProTitle = styled.p`
  margin: 0;
  padding-bottom: 5px;
  //styleName: 16 bold;
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const ProSubtitle = styled.p`
  margin: 0;
  //styleName: 14 regular;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: rgba(255, 255, 255, 0.5);
`;

const ProgressBox = styled.div`
  background-color: #1c1f26;
  padding: 20px;
  border-radius: 16px;
  margin-top: 20px;
  text-align: left;
  width: 90%;
  color: #fff;
`;

const firestore = getFirestore();

const ProInfo = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(null);

  const handleSignInWithGoogle = async () => {
    setLoading(true);
    try {
      const result = await signInWithPopup(auth, googleProvider);
      await handleUser(result.user, "google");
    } catch (error) {
      console.error("Ошибка авторизации через Google:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSignInWithApple = async (authData) => {
    const { authorization, user } = authData;
    const { id_token } = authorization;

    try {
      const appleUser = {
        email: user.email,
        id: id_token,
        provider: "apple",
      };
      await handleUser(appleUser, "apple");
    } catch (error) {
      console.error("Ошибка авторизации через Apple:", error);
    }
  };

  const handleUser = async (user, provider) => {
    const usersByEmailRef = doc(firestore, "usersByEmail", user.email);
    const userEmailSnapshot = await getDoc(usersByEmailRef);

    if (userEmailSnapshot.exists()) {
      const userId = userEmailSnapshot.data().userId;
      const userDocRef = doc(firestore, "users", userId);
      const userSnapshot = await getDoc(userDocRef);

      if (userSnapshot.exists()) {
        const existingUserData = userSnapshot.data();
        setProgress(existingUserData);
        localStorage.setItem("progress", JSON.stringify(existingUserData));
      }
    } else {
      const userId = user.id;
      const newUser = {
        user: {
          id: userId,
          provider,
          email: user.email,
          pro: false,
        },
        data: {},
      };
      await setDoc(doc(firestore, "users", userId), newUser);
      await setDoc(usersByEmailRef, { userId });
      setProgress(newUser);
      localStorage.setItem("progress", JSON.stringify(newUser));
    }
  };

  const handleSignOut = async () => {
    await signOut(auth);
    setProgress(null);
    localStorage.removeItem("progress");
    navigate("/");
  };

  useEffect(() => {
    const storedProgress = localStorage.getItem("progress");
    if (storedProgress) {
      setProgress(JSON.parse(storedProgress));
    }
  }, []);

  return (
    <SignInContainer>
      <HeaderContainer>
        <Title>{!progress ? "Войти в аккаунт" : "Настройки аккаунта"}</Title>
        <CloseButton onClick={() => navigate("/")}>
          <ImgClose src={cross} alt="Close" />
        </CloseButton>
      </HeaderContainer>

      {progress ? (
        <AccountBox>
          <Avatar src={proIcon} alt="Avatar" />
          <Text>{progress.user.email}</Text>
          <InfoText>
            Вы авторизованы через{" "}
            {progress.user.provider === "google" ? "Google" : "Apple"}
          </InfoText>

          <ProInfoText style={{}}>Доступно с MiroLang Pro</ProInfoText>
          <ProBox>
            <ImageIcon src={lock} alt="Pro Icon" />
            <ProText>
              <ProTitle>Открытие уровней</ProTitle>
              <ProSubtitle>
                Откройте доступ к закрытым <br />
                уровням прямо сейчас.
              </ProSubtitle>
            </ProText>
          </ProBox>
          <ProBox>
            <ImageIcon src={unlimWords} alt="Pro Icon" />
            <ProText>
              <ProTitle>Безлимит слов в день</ProTitle>
              <ProSubtitle>
                Учите неограниченное количество
                <br />
                новых слов каждый день.
              </ProSubtitle>
            </ProText>
          </ProBox>
          <ProBox>
            <ProText>
              <ProSubtitle>Настройки подписки</ProSubtitle>
            </ProText>
            <ArrowImg src={arrowRight} alt="Arrow"></ArrowImg>
          </ProBox>

          <LogoutButton onClick={handleSignOut}>Выйти из аккаунта</LogoutButton>
        </AccountBox>
      ) : null}

      {!progress && <p>Загрузка...</p>}
    </SignInContainer>
  );
};

export default ProInfo;
