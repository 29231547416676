import React from "react";
import styled from "styled-components";
import ProgressImage from "../assets/images/stat.jpg";

const BackgroundContainer = styled.div`
  // background-color: #0c0c0d;
  padding: 90px 0 0 0;
  color: #ffffff;
  text-align: center;
  position: relative;
  width: 100%;
  max-width: 1126px;
  @media (max-width: 768px) {
    padding: 20px 0 0 0;
  }
`;

const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(181, 205, 231, 0.1);
  box-shadow: 0px -0.91px 0.91px 0px rgba(0, 0, 0, 0.3) inset;

  box-shadow: 0px 0.91px 0.91px 0px rgba(255, 255, 255, 0.3) inset;

  padding: 20px;
  text-align: center;
  color: #ffffff;
  border-radius: 48px;
  width: 100%;
  max-width: 1136px;
  position: relative;
  @media (max-width: 768px) {
    width: 350px;
    height: 540px;
    padding: 0;
    margin: 0 auto;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  // background-color: rgba(181, 205, 231, 0.1);
  border-radius: 33px;
  max-width: 1136px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 335px;
    height: 525px;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  max-width: 50%;
  padding: 20px 50px 20px 70px;

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 30px 10px 10px 10px;

    text-align: center;
  }

  p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5;

    margin: -20px 0px 70px 0px;

    @media (max-width: 768px) {
      line-height: 1;
      font-size: 14px;
      margin: -10px 0px 20px 0;
    }
  }
`;

const PhoneMockup = styled.div`
  position: absolute;
  right: 85px;
  bottom: -25px;
  zindex: 1000;
  @media (max-width: 768px) {
    position: relative;
    display: flex;
    right: 0;
    top: 0;
  }

  img {
    width: 340px;
    height: 375px;
    border-radius: 16px;

    @media (max-width: 768px) {
      padding-top: 20px;
      width: 251px;
      height: 318px;
      position: relative;
      right: 0;
      top: 0;
    }
  }
`;

const MainText = styled.h1`
  color: #ffffff;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.2;
  margin-top: 50px;
  span {
    color: #ffcc00;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const MainTextMobile = styled.h1`
  display: none;

  @media (max-width: 768px) {
    display: block;
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    margin-top: -10px;

    span {
      color: #ffcc00;
    }
  }
`;

const Text = styled.p`
  color: rgba(255, 255, 255, 0.7);
  font-size: 20px;
  line-height: 1.5;
  @media (max-width: 768px) {
    font-size: 16px;
    // margin-top: -10px;
  }
`;
const Button = styled.button`
  margin: -50px 0 50px 0;
  background-color: #f1cc06;
  width: 63%;
  height: 52px;
  border: none;
  color: #14161b;
  padding: 12px 24px;
  border-radius: 12px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  @media (max-width: 768px) {
    justify-content: center;
    align-items: center;
    width: 172px;
    height: 40px;
    padding: 12px;
    // gap: 10px;
    border-radius: 12px;
    margin: 0 auto;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
  }

  &:hover {
    background-color: #e6b800;
  }
`;

const ProgressTracking = () => {
  return (
    <BackgroundContainer>
      <Section>
        <Content>
          <Card>
            <MainText>
              Смотрите как растёт
              <br /> ваш словарный запас!
            </MainText>
            <MainTextMobile>
              Смотрите как растёт ваш
              <br /> словарный запас!
            </MainTextMobile>
            <Text>
              Вы можете смотреть, сколько слов вы
              <br /> выучили, сколько учите в данный момент и<br /> многое
              другое.
            </Text>
            <Button
              onClick={() =>
                window.open(
                  "https://apps.apple.com/by/app/mirolang-%D1%83%D1%87%D0%B8-%D0%B0%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B5-%D1%81%D0%BB%D0%BE%D0%B2%D0%B0/id6503367928",
                  "_blank"
                )
              }
            >
              Скачать приложение
            </Button>
          </Card>

          <PhoneMockup>
            <img src={ProgressImage} alt="Phone with app" />
          </PhoneMockup>
        </Content>
      </Section>
    </BackgroundContainer>
  );
};

export default ProgressTracking;
