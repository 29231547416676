import React, { useState } from "react";
import styled from "styled-components";
import PhoneImage from "../assets/images/levels.png";
import apple from "../assets/images/apple.svg";
import appleHover from "../assets/images/appleHover.svg";
import google from "../assets/images/google.svg";
import soon from "../assets/images/soon.svg";

const HeroSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 20px;
  color: #ffffff;
  @media (max-width: 768px) {
    display: block;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0;
  }
`;

const HeroContent = styled.div`
  max-width: 600px;

  h1 {
    font-size: 56px;
    margin-bottom: 20px;
    font-weith: 700;
    font-family: Inter;

    @media (max-width: 768px) {
      display: none;
    }
  }

  p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 20px;
    font-weith: 400;
    margin-bottom: 30px;
    font-family: Inter;
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

const H1Mobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Inter;
    font-size: 28px;
    font-weight: 700;
    line-height: 33.89px;
    letter-spacing: 0.38px;
    text-align: center;
    padding: 5px 0;
  }
`;

const PMobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.5);
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    padding: 10px 0;
  }
`;

const DownloadButtons = styled.div`
  display: flex;
  gap: 20px;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    width: 100%;
  }
`;

const PhoneMockup = styled.div`
  img {
    width: 100%;
    max-width: 400px;
    margin-left: 150px;
    flex-direction: column;

    @media (max-width: 768px) {
      width: 350px;
      height: 420px;
      margin-left: 0px;
      padding-top: 40px;
    }
  }
`;

const Img = styled.img`
  cursor: pointer;
  &:hover {
    color: #ffffff;
  }
  @media (max-width: 768px) {
    width: 147px;
    height: 48px;
    top: 291px;
    left: 40px;
    gap: 0px;
    opacity: 0px;
  }
`;

const LineImage = styled.div`
  width: 400px;
  height: 8px;
  border-radius: 8px;
  margin: -10px 0px 0px 153px;
  background-color: rgba(28, 31, 38, 1);
  box-shadow: 0px -0.91px 0.91px 0px rgba(0, 0, 0, 0.3) inset;

  box-shadow: 0px 0.91px 0.91px 0px rgba(255, 255, 255, 0.3) inset;
  @media (max-width: 768px) {
    margin: -8px 0px 0px 0px;
    width: 338px;
    height: 6px;
  }
`;

const ImgLate = styled.img`
  position: absolute;
  width: 54px;
  height: 24px;
  top: -10px;
  right: -10px;
  border-radius: 5px 0px 0px 0px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const Hero = () => {
  const [imgSrc, setImgSrc] = useState(apple);
  return (
    <HeroSection>
      <HeroContent>
        <h1>
          Учи английские
          <br /> слова по карточкам
          <br /> бесплатно!
        </h1>
        <p>
          Скачивай приложение, учите новые слова по уровням,
          <br /> запоминайте по карточной системе.
        </p>

        <H1Mobile>
          Учи английские слова
          <br /> по карточкам
          <br /> бесплатно!
        </H1Mobile>
        <PMobile>
          Скачивай приложение, учите новые слова по
          <br /> уровням, запоминайте по карточной системе.
        </PMobile>

        <DownloadButtons>
          <div>
            <Img
              onClick={() =>
                window.open(
                  "https://apps.apple.com/by/app/mirolang-%D1%83%D1%87%D0%B8-%D0%B0%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B5-%D1%81%D0%BB%D0%BE%D0%B2%D0%B0/id6503367928",
                  "_blank"
                )
              }
              src={imgSrc}
              alt="App Store"
              onMouseEnter={() => setImgSrc(appleHover)}
              onMouseLeave={() => setImgSrc(apple)}
            />
          </div>
          <div style={{ position: "relative" }}>
            <Img src={google} alt="Google Play" />
            <ImgLate src={soon} alt="Coming Soon" />
          </div>
        </DownloadButtons>
      </HeroContent>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <PhoneMockup>
          <img src={PhoneImage} alt="Phone with app" />
        </PhoneMockup>
        <LineImage />
      </div>
    </HeroSection>
  );
};

export default Hero;
