import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, OAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCORwxneo2ony1iwHkq6n1q7mDj8gdzy9k",
  authDomain: "mirolang-fab62.firebaseapp.com",
  databaseURL:
    "https://mirolang-fab62-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "mirolang-fab62",
  storageBucket: "mirolang-fab62.firebasestorage.app",
  messagingSenderId: "59797860773",
  appId: "1:59797860773:web:0b7e6f611ad7960fc9784c",
  measurementId: "G-16SY59YVXJ",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
// const analytics = getAnalytics(app);
export const googleProvider = new GoogleAuthProvider();
export const appleProvider = new OAuthProvider("apple.com");
